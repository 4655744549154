
import Vue from "vue";
import { SurveyRespondents } from "@/models/surveyrespondents";
import i18n from "@/i18n";
import axios from "@/plugins/axios";

export default Vue.extend({
  name: "SurveyRespondents",
  data: () => ({
    countries: [],
    years: [],
    filters: {
      region: [],
      country: [],
      gni: [],
      years: [],
    },
    data: [] as SurveyRespondents[],
    loading: false,
  }),
  computed: {
    lang: {
      get() {
        return i18n ? i18n.locale : "en";
      },
    },
    headers: {
      get() {
        return [
          {
            text: i18n.t("reports.surveyrespondents.headers.country"),
            value: "countryName_en",
            width: "100px",
          },

          {
            text: i18n.t("reports.surveyrespondents.headers.year"),
            value: "year",
            width: "100px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.organizationName"),
            value: "organizationName",
            width: "200px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.organizationCity"),
            value: "organizationCity",
            width: "100px",
          },
          { 
            text: i18n.t("reports.surveyrespondents.headers.organizationPhone"),
            value: "organizationPhone", 
            width: "200px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.organizationEmail"),
            value: "organizationEmail",
            width: "200px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.completedByFirstName"),
            value: "completedByFirstName",
            width: "200px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.completedByLastName"),
            value: "completedByLastName",
            width: "200px",
          },
          {
            text: i18n.t("reports.surveyrespondents.headers.completedByEmail"),
            value: "completedByEmail",
            width: "200px",
          },
        ];
      },
    },
  },
  methods: {
    loadFilterData() {
      this.loadCountries();
      this.loadYears();
    },
    loadCountries() {
      axios
          .get("MasterQuery/countries")
          .then((res) => {
            this.countries = res.data;
          })
          .catch((err) => console.log(err));
    },
    loadYears() {
      axios
          .get("MasterQuery/years")
          .then((res) => {
            this.years = res.data;
          })
          .catch((err) => console.log(err));
    },
    onResetFilters() {
      this.filters.country = [];
      this.filters.years = [];
    },
    deleteFilter(filter: string, item: number) {
      switch (filter) {
        case "country": {
          const index = this.filters["country"].findIndex(
              (element: number) => element === item
          );
          this.filters["country"].splice(index, 1);
          return;
        }
        case "years": {
          const index = this.filters["years"].findIndex(
              (element: number) => element === item
          );
          this.filters["years"].splice(index, 1);
          return;
        }
      }
    },
    loadData() {
      this.loading = true;
      axios
          .post("survey/respondents", {
            countries: this.filters.country,
            years: this.filters.years,
          })
          .then((res: any) => {
            this.data = res.data;
            this.loading = false;
          })
          .catch((err) => console.log(err));
    },
    exportReport() {
      this.loading = true;
      axios
          .post(
              "survey/respondents/export?culture=" + this.lang,
              {
                countries: this.filters.country,
                years: this.filters.years
              },
              {responseType: "blob"}
          )
          .then((res) => {
            this.loading = false;
            const fileName = `${i18n.t("reports.surveyrespondents.title")}`;
            this.downloadExcel(fileName, res.data);
          })
          .catch((err) => {
                this.loading = false;
                console.log(err)
              }
          );
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], {type: "application/xlsx"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.loadFilterData();
  }
});
